import Vue from 'vue';
import BarChart from './bar-chart.js';
import selectDateRange from 'vue_root/mixins/selectDateRange.mixin.js';
import SassVariablesMixin from 'vue_root/mixins/sass-variables.mixin.js';

export default {
    components: {
        BarChart,
    },
    props: {
        displayErrorMessage: {
            type: Function,
            required: true
        },
    },
    mixins: [
        selectDateRange,
        SassVariablesMixin,
    ],
    data,
    computed: getComputed(),
    created,
    methods: getMethods(),
    watch: getWatchHandlers(),
};

function data(){
    return {
        selectedDateRange: null,
        startDate: null,
        endDate: null,

        graphShowAllAccounts: true,
        loadingAssignments: false,
        incomeAccount: {},
        checkingSubAccounts: [],

        drawBarChartKey: 0,
        aggregateData: null,
    };
}

function created(){
    const vm = this;
    vm.selectedDateRange = vm.dateRangeOptions[0];
}

function getComputed(){
    return {
        selectedExpensesAmount(){
            return this.checkingSubAccounts.reduce((total, subAccount) => subAccount.showGraph ? subAccount.amountAssigned + total : total, 0);
        },
        totalAmount(){
            return this.allBankAccounts.reduce((total, subAccount) => subAccount.showGraph ? subAccount.amountAssigned + total : total, 0);
        },
        allBankAccounts(){
            return [this.incomeAccount, ...this.checkingSubAccounts];
        },
        disabledStartDates(){
            const vm = this;
            const disabledDates = {};
            disabledDates.to = Vue.moment().subtract(2, 'years').toDate();
            disabledDates.from = vm.endDate;
            return disabledDates;
        },
        disabledEndDates(){
            const disabledDates = {};
            disabledDates.to = Vue.moment().subtract(2, 'years').toDate();
            disabledDates.from = new Date();
            return disabledDates;
        },
        chartjsData(){
            const vm = this;
            const accountsLoaded = vm.incomeAccount.id && vm.checkingSubAccounts[0];
            if(!accountsLoaded || !vm.aggregateData){
                return null;
            }

            const datasets = [];
            vm.incomeAccount.showGraph && datasets.push(formatDataset(vm.incomeAccount, 'income'));
            vm.checkingSubAccounts.forEach(subAccount => subAccount.showGraph && datasets.push(formatDataset(subAccount, 'checking')));

            return {
                labels: vm.aggregateData.labels,
                datasets
            };

            function formatDataset(bankAccount, group){
                return {
                    label: bankAccount.name,
                    backgroundColor: vm.getAccountColor(bankAccount),
                    data: vm.aggregateData.data[bankAccount.id]?.values || [],
                    stack: group
                };
            }
        },
    };
}

function getWatchHandlers(){
    return {
        selectedDateRange(){
            const vm = this;
            const dateRange = vm.getDatesByOptionValue(vm.selectedDateRange);
            if(dateRange){
                vm.startDate = dateRange[0].toDate();
                vm.endDate = dateRange[1].toDate();
            }

            vm.fetchAssignmentsAggregate();
        },
        '$store.state.authorized.bankAccounts.bankAccounts'(){
            this.fetchAssignmentsAggregate();
        },
        chartjsData(){
            this.drawBarChart();
        }
    };
}

function getMethods(){
    return {
        fetchAssignmentsAggregate,
        resetAccounts,
        setBankAccountAmountAssigned,
        drawBarChart,
        getPercentAmongTotalAssignments,
        toggleShowGraph,
        redirectProfitAndLoss,
    };

    function redirectProfitAndLoss(){
        this.$router.push({ path: '/profit-loss', query: { date_range: this.selectedDateRange }});
    }

    function fetchAssignmentsAggregate(){
        const vm = this;
        vm.resetAccounts();
        const bankAccountIds = vm.allBankAccounts.map(subAccount => subAccount.id);

        const query = {
            start_date: Vue.moment(vm.startDate).format('YYYY-MM-DD'),
            end_date: Vue.moment(vm.endDate).format('YYYY-MM-DD'),
            bank_account_ids: bankAccountIds
        };

        vm.loadingAssignments = true;
        return Vue.appApi().authorized().account().reports().getAssignmentsAggregate(query)
            .then(setAssignments)
            .catch(handleLoadingError)
            .finally(resetLoadingState);

        function setAssignments({ data }){
            vm.aggregateData = data;
            vm.allBankAccounts.forEach(vm.setBankAccountAmountAssigned);
            vm.drawBarChart();
        }

        function handleLoadingError(error){
            vm.assignments = [];
            vm.displayErrorMessage(error);
        }

        function resetLoadingState(){
            vm.loadingAssignments = false;
        }
    }

    function setBankAccountAmountAssigned(bankAccount){
        const vm = this;
        if(bankAccount && vm.aggregateData && vm.aggregateData.data[bankAccount.id]){
            bankAccount.amountAssigned = vm.aggregateData.data[bankAccount.id]?.total || 0;
        }
    }

    function resetAccounts(){
        const vm = this;
        resetIncomeAccount();
        resetCheckingSubAccounts();

        function resetIncomeAccount(){
            const localCopyOfIncomeAccount = Vue.dymUtilities.cloneObject(vm.$store.state.authorized.bankAccounts.bankAccounts)
                .filter(bankAccount => bankAccount.purpose === 'income')[0];
            if(!localCopyOfIncomeAccount){
                vm.incomeAccount = {};
                return;
            }

            localCopyOfIncomeAccount.amountAssigned = 0;
            localCopyOfIncomeAccount.showGraph = true;

            vm.incomeAccount = localCopyOfIncomeAccount;
        }

        function resetCheckingSubAccounts(){
            const primaryCheckingAccount = vm.$store.state.authorized.bankAccounts.bankAccounts.filter(bankAccount => bankAccount.slug === 'primary_checking')[0];
            if(!primaryCheckingAccount){
                vm.checkingSubAccounts = [];
                return;
            }

            const hiddenPurpose = ['cc_payoff', 'income'];
            const localCopyOfCheckingSubAccounts = Vue.dymUtilities.cloneObject(primaryCheckingAccount.sub_accounts.filter(subAccount => !hiddenPurpose.includes(subAccount.purpose)));
            vm.checkingSubAccounts = localCopyOfCheckingSubAccounts.map(subAccount => {
                subAccount.amountAssigned = 0;
                subAccount.showGraph = true;
                return subAccount;
            });
        }
    }

    function drawBarChart(){
        this.drawBarChartKey++;
    }

    function getPercentAmongTotalAssignments(bankAccount){
        const vm = this;
        const subAccounts = vm.checkingSubAccounts.map((account) => {
            account.amount = account.showGraph ? account.amount = account.amountAssigned : 0;
            return account;
        });
        const totalAbsExpenseAmount = subAccounts.reduce((total, subAccount) => {
            return bankAccount.showGraph ? Math.abs(subAccount.amount) + total : 0;
        }, 0);
        if(totalAbsExpenseAmount <= 0){
            return 0;
        }

        const percent = Math.abs(bankAccount.amountAssigned) * 100 / totalAbsExpenseAmount;
        return percent.toFixed(0);
    }

    function toggleShowGraph(triggerBankAccount){
        const vm = this;
        if(triggerBankAccount){
            vm.graphShowAllAccounts = isShowingAllAccounts();
        } else {
            bulkSetShowGraph(vm.graphShowAllAccounts);
        }

        this.drawBarChart();

        function isShowingAllAccounts(){
            return vm.incomeAccount.showGraph && vm.checkingSubAccounts.every(subAccount => subAccount.showGraph);
        }

        function bulkSetShowGraph(newValue){
            vm.incomeAccount.showGraph = newValue;
            vm.checkingSubAccounts.forEach(subAccount => {
                subAccount.showGraph = newValue;
            });
        }
    }
}
